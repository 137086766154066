<template>
  <v-container fluid>
    <BasePageTitle>Projects</BasePageTitle>
    <BaseNavigationButton
      tooltipText="Create New Project"
      nextRoute="project-create"
      navIcon="mdi-plus"
      color="secondary"
      route
      :next="false"
      dataCy="create-new-project-button"
    />
    <v-divider vertical class="mx-1" />
    <BaseRefreshButton
      color="secondary"
      @nextAction="
        reloadProjects({
          filter: !!projectSearch.type,
          type: projectSearch.type,
          value: projectSearch.value,
        })
      "
    />
    <v-spacer />
    <v-sheet outlined class="ma-1 pa-5">
      <v-toolbar color="warning" dark dense>
        <v-toolbar-title>Admin Only - Search all Projects</v-toolbar-title>
      </v-toolbar>
      <v-row dense v-if="isAdmin">
        <v-col cols="12" md="6">
          <BaseSearchUserSelect
            ref="search-user"
            label="Display User Projects"
            :items="users"
            :itemText="`email`"
            :itemValue="`pk`"
            :isSelectDisabled="
              !!selectedAdmins || !!selectedProject || !!selectedPart
            "
            :allowMultiple="false"
            :loading="usersLoading && dataCaller == 'user'"
            dataType="user"
            dataKey="user_id"
            key="user"
            @clearFilters="clearUserFilter"
            @filterQuotes="reloadUser"
            @loadData="onLoadData($event, `users`)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <BaseSearchUserSelect
            ref="search-admin"
            label="Display Admin Projects"
            :items="adminList"
            :itemText="`email`"
            :itemValue="`pk`"
            :isSelectDisabled="
              !!selectedUser || !!selectedProject || !!selectedPart
            "
            :allowMultiple="false"
            :loading="usersLoading && dataCaller == 'admin'"
            dataType="admin"
            dataKey="user_id"
            key="admins"
            @clearFilters="clearAdminFilter"
            @filterQuotes="reloadMultiple"
            @loadData="onLoadData($event, `users`)"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <BaseSearchProjectTextField
            ref="search-project"
            label="Search by Project Name"
            dataType="project"
            dataKey="search"
            :isSelectDisabled="
              !!selectedUser || !!selectedAdmins || !!selectedPart
            "
            @clearFilters="clearProjectFilter"
            @filterQuotes="reloadProject"
          />
        </v-col>
        <v-col cols="12" md="6">
          <BaseSearchProjectTextField
            ref="search-fixture"
            label="Search by SKU"
            dataType="fixture"
            dataKey="search"
            :isSelectDisabled="
              !!selectedUser || !!selectedAdmins || !!selectedProject
            "
            @clearFilters="clearFixtureFilter"
            @filterQuotes="reloadFixture"
          />
          <v-list-item dense three-line class="ml-8">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 info--text"
                >Note - Searching a SKU only searches the four middle digits of
                a full_part_number.</v-list-item-title
              >
              <v-list-item-subtitle class="info--text">
                - A full part number is 601-0332-00, but only the 0322 digits
                are searchable.
              </v-list-item-subtitle>
              <v-list-item-subtitle class="info--text">
                - 0332 will work, 601 will not. 033 will also work.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-sheet>
    <div v-if="projects && projects.length > 0">
      <v-row class="mt-5">
        <v-col
          cols="12"
          xl="3"
          lg="4"
          sm="6"
          v-for="project in filteredProjects"
          :key="project.pk"
        >
          <ProjectItem
            :project="project"
            :defaultDut="getFirstDut(project)"
            :fdState="fdState"
            :numberOfDuts="getNumberOfDuts(project)"
            @delete="deleteHandler"
            @deleteDut="dutDeleteHandler"
            @deleteTPCB="tpcbDeleteHandler"
            @saveTPCB="saveTPCBHandler"
            @saveQuote="saveQuoteHandler"
            @onQuoteDetailsView="quoteDetailsView"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-pagination
            v-if="
              pages > 0 &&
                (projects.length >= totalCount ||
                  projects.length >= page * pageSize)
            "
            class="pagination my-5"
            circle
            v-model="page"
            :length="pages"
            @input="updatePage"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <v-layout v-else class="justify-center mt-3" row>
      <span class="body-2 font-weight-bold">No project to display</span>
    </v-layout>
    <BaseSubMenuPopup
      :showMenu="showMenu"
      :xCoord="xCoord"
      :yCoord="yCoord"
      :nextAction="nextAction"
      @onClick="subMenuClickHandler"
    />
    <QuoteDetailsModal
      :dialog="quoteDetailsDialog"
      :quote="quote"
      :dutDetails="dutDetails"
      :users="users"
      :companies="companyList"
      :projectType="projectType"
      :projectTimeline="projectTimeline"
      :fixtureWiring="fixtureWiring"
      @goToFixtureDesign="onGoToFixtureDesign"
      @gotoUpdateQuote="onUpdateQuote"
      @onDownloadFile="downloadFile"
      @onActivateMenu="activateMenu"
      @closeQuoteDetailsModal="onCloseQuoteDetailsModal"
    />
    <FixtureDesignModal
      :fddialog="fdDetailsDialog"
      :fixtureDesign="fixtureDesign"
      :selectedProject="selProject"
      :fdState="fdState"
      :fdStateLongList="fdStateLongList"
      @gotoQuoteModal="quoteDetailsView"
      @onGoToFulfillment="gotoFulfillment"
      @onActivateMenu="activateMenu"
      @closeFDDetailsModal="onCloseFDDetailsModal"
    />
  </v-container>
</template>
<script>
import SelectDataLoader from "@/mixins/SelectDataLoader";
import SubMenuPopup from "@/mixins/SubMenuPopup";
import { fileDownloader } from "@/helpers/basehelper.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ProjectIndex",
  mixins: [SelectDataLoader, SubMenuPopup],
  components: {
    ProjectItem: () => import("../components/ProjectItem.vue"),
    QuoteDetailsModal: () =>
      import("@/modules/quotes/components/QuoteDetailsModal.vue"),
    FixtureDesignModal: () =>
      import("@/modules/quotes/components/FixtureDesignModal.vue"),
  },
  data() {
    return {
      formError: false,
      dialog: false,
      confirmMode: false,
      confirmMessage: "",
      confirmAction: "stopdialog",
      toolbarCollapse: true,
      searchProjectString: "",
      searchFixtureString: "",
      selectedUser: null,
      selectedAdmins: null,
      selectedProject: null,
      selectedPart: null,
      activeSearch: null,
      quoteDetailsDialog: false,
      fdDetailsDialog: false,
      page: 1,
      pages: 0,
      pageSize: 20,
      totalCount: 0,
      prevPage: 1,
      searchableParams: null,
    };
  },
  computed: {
    ...mapGetters({
      projects: "projects/projects",
      companyList: "projects/companyList",
      selProject: "projects/selProject",
      projectSearch: "ui/projectSearch",
      projectSearchString: "ui/projectSearchString",
      fixtureDesign: "fixturedesign/fixtureDesign",
      fdState: "fixturedesign/fdState",
      fdStateLongList: "ui/fdStateLongList",
      quote: "quotes/quote",
      dutDetails: "quotes/dutDetailsView",
      projectType: "quotes/projectType",
      projectTimeline: "quotes/projectTimeline",
      fixtureWiring: "quotes/fixtureWiring",
      isAdmin: "auth/isAdmin",
    }),
    user() {
      return this.$store.state.auth.user;
    },
    adminList() {
      return this.users.filter((element) => {
        return element.is_staff;
      });
    },
    filteredProjects() {
      return this.projects
        .map((project) => {
          return {
            ...project,
            user_name:
              Object.keys(project.user_details).length > 0
                ? project.user_details.username
                : null,
            user_email:
              Object.keys(project.user_details).length > 0
                ? project.user_details.email
                : null,
            company_request:
              Object.keys(project.user_details).length > 0
                ? project.user_details.company_request
                : null,
            company_name:
              Object.keys(project.company_details).length > 0
                ? project.company_details.name
                : null,
          };
        })
        .slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    },
  },
  methods: {
    fileDownloader,
    ...mapActions({
      initializeProjects: "projects/clearProjects",
      fetchProjects: "projects/getProjects",
      fetchMultiple: "projects/getMultipleProjects",
      getCompanies: "projects/fetchCompanies",
      removeProject: "projects/deleteProject",
      searchProjects: "projects/searchProjects",
      deleteDutConfig: "dutconfigs/removeDutById",
      patchDutConfig: "dutconfigs/updateDutConfigById",
      fetchState: "fixturedesign/getFixtureDesignState",
      getFixtureDesign: "fixturedesign/getFixtureDesign",
      patchFixtureDesign: "fixturedesign/patchFixtureDesign",
      patchDesignFilePackage: "fixturedesign/patchDesignFilePackage",
      saveTPCBConfig: "tpcb/postTPCBConfig",
      removeTPCBConfig: "tpcb/deleteTPCBConfig",
      fetchQuote: "quotes/getQuote",
      fetchStatus: "quotes/getQuoteStatus",
      fetchDutDetails: "quotes/getDutDetailsView",
      searchFixtureDesign: "fixturedesign/searchFixtures",
      updateProjectSearch: "ui/projectSearch",
      updateSearchStr: "ui/projectSearchString",
      toggleLoading: "ui/loading",
      snackBar: "ui/snackBar",
    }),
    formatDate(date) {
      return new Date(date);
    },
    async reloadProjects({ filter, type, value }) {
      try {
        this.initializeProjects();
        this.page = 1;
        if (!filter) {
          this.searchableParams = { user_id: this.user.pk };
          const response = await this.fetchProjects({
            params: { page: 1, user_id: this.user.pk },
          });
          this.totalCount = response.count;
          this.pages = Math.ceil(response.count / this.pageSize);
        } else {
          if (type == "user" || type == "admin") {
            this.searchableParams = { user_id: value };
            this.page = this.prevPage = 1;
            const response = await this.fetchProjects({
              params: { user_id: value, page: 1 },
            });
            this.totalCount = response.count;
            this.pages = Math.ceil(response.count / this.pageSize);
            this.selectedUser = value;
            this.selectedAdmins = this.selectedProject = this.selectedPart = null;
          } else if (type == "project") {
            this.searchableParams = { search: value };
            this.page = this.prevPage = 1;
            const response = await this.fetchProjects({
              params: { search: value, page: 1 },
            });
            this.totalCount = response.count;
            this.pages = Math.ceil(response.count / this.pageSize);
            await this.fetchProjects({
              params: {
                search: value,
              },
            });
            this.selectedProject = value;
            this.selectedUser = this.selectedAdmins = this.selectedPart = null;
          } else {
            this.reloadFixture({
              type,
              params: {
                search: value,
              },
            });
            this.selectedPart = value;
            this.selectedUser = this.selectedAdmins = this.selectedProject = null;
          }
        }
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async reloadUser({ params }) {
      try {
        this.selectedAdmins = this.selectedProject = this.selectedPart = null;
        this.selectedUser = params.user_id;
        this.searchableParams = params;
        this.initializeProjects();
        this.page = this.prevPage = 1;
        const response = await this.fetchProjects({
          params: Object.assign(params, { page: 1 }),
        });
        this.totalCount = response.count;
        this.pages = Math.ceil(response.count / this.pageSize);
        this.updateProjectSearch({ type: "user", value: this.selectedUser });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async reloadMultiple({ params }) {
      try {
        this.selectedUser = this.selectedProject = this.selectedPart = null;
        this.selectedAdmins = params.user_id;
        this.searchableParams = params;
        this.initializeProjects();
        this.page = this.prevPage = 1;
        const response = await this.fetchProjects({
          params: Object.assign(params, { page: 1 }),
        });
        this.totalCount = response.count;
        this.pages = Math.ceil(response.count / this.pageSize);
        this.updateProjectSearch({ type: "admin", value: this.selectedAdmins });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async reloadProject({ type, params }) {
      try {
        this.selectedUser = this.selectedAdmins = this.selectedPart = null;
        this.selectedProject = params.search;
        this.searchableParams = params;
        this.initializeProjects();
        this.page = this.prevPage = 1;
        const response = await this.fetchProjects({
          params: Object.assign(params, { page: 1 }),
        });
        this.totalCount = response.count;
        this.pages = Math.ceil(response.count / this.pageSize);
        this.updateProjectSearch({
          type: type,
          value: this.selectedProject,
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async reloadFixture({ type, params }) {
      try {
        this.selectedUser = this.selectedAdmins = this.selectedProject = null;
        this.selectedPart = params.search;
        this.searchableParams = params;
        this.initializeProjects();
        this.page = this.prevPage = 1;
        const response = await this.searchFixtureDesign({
          params: Object.assign(params, { page: 1 }),
        });
        this.totalCount = response.count;
        this.pages = Math.ceil(response.count / this.pageSize);
        const projectList = response.results.map((element) => element.project);
        console.log(projectList);
        await this.fetchProjects({
          params: {
            pks: projectList.toString(),
          },
        });
        this.updateProjectSearch({ type: type, value: this.selectedPart });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onSearchProjectString(value) {
      if (!value) {
        this.searchProjectString = "";
      }
      this.updateSearchStr(value);
    },
    onSearchFixtureString(value) {
      if (!value) {
        this.searchFixtureString = "";
      }
      this.updateSearchStr(value);
    },
    async initUsers() {
      if (this.user.is_staff) {
        try {
          await this.getUsers();
        } catch (err) {
          this.$store.commit(
            "ui/SNACK_BAR",
            err.message || "Failed to process request, try later."
          );
        }
      }
    },
    async deleteHandler(pk) {
      try {
        await this.removeProject(pk);
        this.$store.commit("projects/REMOVE_SINGLE_PROJECT", pk);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async dutDeleteHandler({ pk, initThumbnail = false, project = null }) {
      console.log(pk, initThumbnail, project);
      try {
        await this.deleteDutConfig(pk);
        if (initThumbnail) {
          const response = await this.getFixtureDesign({
            params: { project_id: project },
          });
          if (response.design_file_packages.length > 0) {
            let formData = new FormData();
            formData.append("thumbnail", "");
            await this.patchDesignFilePackage({
              id: response.design_file_packages[0].id,
              data: formData,
            });
          }
        }
        await this.reloadProjects({
          filter: !!this.projectSearch.type,
          type: this.projectSearch.type,
          value: this.projectSearch.value,
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async tpcbDeleteHandler(pk) {
      try {
        await this.removeTPCBConfig(pk);
        await this.reloadProjects({
          filter: !!this.projectSearch.type,
          type: this.projectSearch.type,
          value: this.projectSearch.value,
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    clearUserFilter() {
      this.selectedUser = null;
      this.updateSearchTextField({
        refname: "search-user",
        payload: null,
      });
      if (this.projectSearch.type || this.projectSearch.value) {
        this.reloadProjects({ filter: false, type: null, value: null });
        this.updateProjectSearch({ type: null, value: null });
      }
    },
    clearAdminFilter() {
      this.selectedAdmins = null;
      this.updateSearchTextField({
        refname: "search-admin",
        payload: null,
      });
      if (this.projectSearch.type || this.projectSearch.value) {
        this.reloadProjects({ filter: false, type: null, value: null });
        this.updateProjectSearch({ type: null, value: null });
      }
    },
    clearProjectFilter() {
      this.selectedProject = null;
      if (this.projectSearch.type || this.projectSearch.value) {
        this.reloadProjects({ filter: false, type: null, value: null });
        this.updateProjectSearch({ type: null, value: null });
      }
    },
    clearFixtureFilter() {
      this.selectedPart = null;
      if (this.projectSearch.type || this.projectSearch.value) {
        this.reloadProjects({ filter: false, type: null, value: null });
        this.updateProjectSearch({ type: null, value: null });
      }
    },
    isDCavailable() {
      return Object.keys(this.project.dut_config_details).length > 0;
    },
    getFirstDut(project) {
      const arrayList =
        Object.keys(project.dut_config_details).length > 0
          ? Object.entries(project.dut_config_details).map(([k, v]) => ({
              pk: k,
              name: v[Object.keys(v)],
            }))
          : null;
      return Object.keys(project.dut_config_details).length > 0
        ? arrayList[0].pk
        : null;
    },
    getNumberOfDuts(project) {
      return Object.keys(project.dut_config_details).length;
    },
    async saveTPCBHandler(payload) {
      const actionPayload = {
        user: this.user.pk,
        project: payload.projectId,
        fixture_design: payload.fdId,
        name: payload.tpcbName,
        connector_blocks: [],
      };
      try {
        await this.saveTPCBConfig(actionPayload);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.$router.push("/tpcb/project/" + payload.projectId);
    },
    async saveQuoteHandler(payload) {
      try {
        await this.patchFixtureDesign(payload);
        await this.reloadProjects({
          filter: !!this.projectSearch.type,
          type: this.projectSearch.type,
          value: this.projectSearch.value,
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async quoteDetailsView(pk) {
      if (
        Object.keys(this.quote).length > 0 &&
        this.dutDetails.length > 0 &&
        this.quote?.id == pk
      ) {
        this.quoteDetailsDialog = true;
        return;
      }
      try {
        this.toggleLoading("Loading Quote Data");
        await Promise.all([
          this.fetchQuote(pk),
          this.fetchDutDetails({ params: { quote_id: pk } }),
          this.get_users(),
        ]);
        this.toggleLoading();
        this.quoteDetailsDialog = true;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onCloseQuoteDetailsModal() {
      this.quoteDetailsDialog = false;
    },
    gotoFulfillment({ fdId, newTab = false }) {
      if (newTab) {
        let routeData = this.$router.resolve({
          name: "fulfillment",
          params: { id: fdId },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: "fulfillment",
          params: { id: fdId },
        });
      }
    },
    onUpdateQuote({ quoteId, activeTab, newTab = false }) {
      if (newTab) {
        localStorage.setItem(
          "quote",
          JSON.stringify({ active_tab: activeTab })
        );
        let routeData = this.$router.resolve({
          name: "quote-edit",
          params: { id: quoteId, activeTab },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: "quote-edit",
          params: { id: quoteId, activeTab },
        });
      }
    },
    async downloadFile({ url, filename }) {
      const actionPayload = {
        url,
        file: filename,
      };
      this.toggleLoading("Downloading, one moment please");
      await this.fileDownloader(actionPayload);
      this.toggleLoading();
    },
    async onGoToFixtureDesign(payload) {
      this.onCloseQuoteDetailsModal();
      if (
        Object.keys(this.fixtureDesign).length > 0 &&
        this.fdState.length > 0 &&
        this.fdStateLongList.length > 0 &&
        Object.keys(this.selProject).length > 0 &&
        this.fixtureDesign?.project == payload
      ) {
        this.fdDetailsDialog = true;
        return;
      }
      try {
        this.toggleLoading("Loading data of Fixture Design");
        await Promise.all([
          this.getFixtureDesign({
            params: { project_id: payload },
          }),
          this.fetchState(),
          this.$store.dispatch("fixturedesign/getDesignFileOptions"),
          this.$store.dispatch("projects/fetchProject", payload),
        ]);
        this.toggleLoading();
        this.fdDetailsDialog = true;
      } catch (err) {
        this.toggleLoading();
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onCloseFDDetailsModal() {
      this.fdDetailsDialog = false;
    },
    updateSearchTextField({ refname, payload }) {
      this.$refs[refname] && this.$refs[refname].updateSelection(payload);
    },
    async updatePage(pageIndex) {
      console.log(
        "Update Page",
        (pageIndex - 1) * this.pageSize,
        pageIndex * this.pageSize,
        pageIndex
      );
      if (
        this.projects.length >= this.totalCount ||
        this.projects.length >= pageIndex * this.pageSize
      )
        return;
      let response;
      console.log(
        "BEFORE IF",
        pageIndex - this.prevPage,
        pageIndex,
        this.prevPage
      );
      if (this.selectedPart) {
        if (pageIndex - this.prevPage > 1) {
          console.log(
            "IF",
            pageIndex - this.prevPage,
            pageIndex,
            this.prevPage
          );
          for (let i = this.prevPage + 1; i <= pageIndex; i++) {
            response = await this.searchFixtureDesign({
              params: Object.assign(this.searchableParams, { page: i }),
            });
            const projectList = response.results.map(
              (element) => element.project
            );
            await this.fetchProjects({
              params: {
                pks: projectList.toString(),
              },
            });
          }
        } else {
          response = await this.searchFixtureDesign({
            params: Object.assign(this.searchableParams, { page: pageIndex }),
          });
          const projectList = response.results.map(
            (element) => element.project
          );
          await this.fetchProjects({
            params: {
              pks: projectList.toString(),
            },
          });
        }
      } else {
        if (pageIndex - this.prevPage > 1) {
          console.log(
            "IF",
            pageIndex - this.prevPage,
            pageIndex,
            this.prevPage
          );
          for (let i = this.prevPage + 1; i <= pageIndex; i++) {
            response = await this.fetchProjects({
              params: Object.assign(this.searchableParams, { page: i }),
            });
          }
        } else {
          console.log("ELSE");
          response = await this.fetchProjects({
            params: Object.assign(this.searchableParams, { page: pageIndex }),
          });
        }
      }
      this.totalCount = response.count;
      this.pages = Math.ceil(response.count / this.pageSize);
      this.prevPage = pageIndex;
    },
    async updatePartPage(pageIndex) {
      console.log(
        "Update Part Page",
        (pageIndex - 1) * this.pageSize,
        pageIndex * this.pageSize,
        pageIndex
      );
      return;
    },
  },
  async created() {
    try {
      if (
        this.projects.length == 0 ||
        !this.projectSearch.type ||
        !this.projectSearch.value
      ) {
        await this.reloadProjects({
          filter: !!this.projectSearch.type,
          type: this.projectSearch.type,
          value: this.projectSearch.value,
        });
      }
      this.toggleLoading("Fetching supporting data");
      await Promise.all([this.fetchState(), this.fetchStatus()]);
    } catch (err) {
      this.toggleLoading();
      this.snackBar(null);
    }
    this.selectedUser = this.selectedAdmins = this.selectedProject = this.selectedPart = null;
    if (this.projectSearch && this.projectSearch.type == "user") {
      this.selectedUser = this.projectSearch.value;
      this.updateSearchTextField({
        refname: "search-user",
        payload: this.projectSearch.value,
      });
    } else if (this.projectSearch && this.projectSearch.type == "admin") {
      this.selectedAdmins = this.projectSearch.value;
      this.updateSearchTextField({
        refname: "search-admin",
        payload: this.projectSearch.value,
      });
    } else if (this.projectSearch && this.projectSearch.type == "project") {
      this.selectedProject = this.projectSearch.value;
      this.updateSearchTextField({
        refname: "search-project",
        payload: this.projectSearch.value,
      });
    } else if (this.projectSearch && this.projectSearch.type == "fixture") {
      this.selectedPart = this.projectSearch.value;
      this.updateSearchTextField({
        refname: "search-fixture",
        payload: this.projectSearch.value,
      });
    }
    this.toggleLoading();
  },
};
</script>
