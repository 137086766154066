export default {
  data() {
    return {
      usersLoading: false,
      companiesLoading: false,
      dataCaller: null
    };
  },
  computed: {
    users() {
      return this.$store.getters["projects/usersList"];
    },
    companies() {
      return this.$store.getters["projects/companyList"];
    },
  },
  methods: {
    async get_users() {
      await this.$store.dispatch("projects/fetchUsers");
    },
    async get_companies() {
      await this.$store.dispatch("projects/fetchCompanies");
    },
    async onLoadData(event =  null, data_type) {
      console.log("Data loader", data_type, this[`${data_type}Loading`], event);
      if (this[data_type].length > 0) return;
      this.dataCaller = event;
      this[`${data_type}Loading`] = true;
      console.log("Data loader", data_type, this[`${data_type}Loading`]);
      const customGetters = `get_${data_type}`
      await this[customGetters]()
      this[`${data_type}Loading`] = false;
      console.log("Data loader", data_type, this[`${data_type}Loading`]);
    }
  }
}